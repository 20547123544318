import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["startDate", "insurancePlan"];
  static values = { url: String };

  connect() {
    document.addEventListener("unit-number", this.handleSelection.bind(this));
    document.addEventListener("insurance-plan", this.handleInsuranceSelection.bind(this));
  }

  handleSelection(event) {
    this.unitNumber = event.detail.unitNumber
    this.calculatePriceBreakdown(event)
  }

  handleInsuranceSelection(event) {
    this.insurancePlan = event.detail.insurancePlan
    this.calculatePriceBreakdown(event)
  }

  calculatePriceBreakdown(event) {
    const startDate = this.startDateTarget.value

    if (this.hasInsurancePlanTarget) {
      this.url = this.urlValue + `?start_date=${startDate}&unit=${this.unitNumber}&insurance_plan=${this.insurancePlanTarget.value}`
    } else if (this.insurancePlan) {
      this.url = this.urlValue + `?start_date=${startDate}&unit=${this.unitNumber}&insurance_plan=${this.insurancePlan}`
    } else {
      this.url = this.urlValue + `?start_date=${startDate}&unit=${this.unitNumber}`
    }

    fetch(this.url)
      .then((r) => r.text())
      .then((html) => {
        const fragment = document
          .createRange()
          .createContextualFragment(html);

        const broadcastEvent = new CustomEvent("price-summary", {
          detail: {
            content: html
          }
        })
        document.dispatchEvent(broadcastEvent)
        return false
      });
  }
}
