import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["insurancePlan"];
  static values = { url: String };

  connect() {
  }

  broadcastInsurancePlan() {
    const broadcastEvent = new CustomEvent("insurance-plan", {
      detail: {
        insurancePlan: this.insurancePlanTarget.value
      }
    })
    document.dispatchEvent(broadcastEvent)
    return false
  }
}
