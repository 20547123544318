import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["unitNumber"];
  static values = { url: String };

  connect() {
    const broadcastEvent = new CustomEvent("unit-number", {
      detail: {
        unitNumber: this.unitNumberTarget.textContent || this.unitNumberTarget.value
      }
    })
    document.dispatchEvent(broadcastEvent)
    return false
  }

  broadcastUnitNumber() {
    const broadcastEvent = new CustomEvent("unit-number", {
      detail: {
        unitNumber: this.unitNumberTarget.textContent || this.unitNumberTarget.value
      }
    })
    document.dispatchEvent(broadcastEvent)
    return false
  }
}
