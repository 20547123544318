import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["summary"];

  connect() {
    document.addEventListener("price-summary", this.handleSelection.bind(this));
  }

  handleSelection(event) {
    this.summaryTarget.innerHTML = event.detail.content
  }

  disconnect() {
    if (this.hasSummaryTarget) {
      this.summaryTarget.remove();
    }
  }
}
